import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
import Column from '@components/organisms/Column';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
import ListText from '@components/organisms/ListText';
import ItemLogo from '@components/molecules/items/ItemLogo/index';
import nextLogo from '@assets/images/projects/eca/nextjs.svg';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#DA1B28';
const footerRelatedLinks = [
	{
		title: 'Freeride World Tour',
		url: '/en/projects/fwt-freeride-world-tour/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectEcaPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_001.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_001-mobile.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_003.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_004.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_005.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_006.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_009.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_010.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_011.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: { eq: "projects/eca/SUPT_ECA_012.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/eca-vaud/',
					lang: 'fr',
				},
			]}
			title="ECA Vaud - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="ECA Vaud"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Projet">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Simplify and unify a complex architecture. Propose a
							new digital signature.
						</p>

						<ListText
							className="element-spacing"
							title="Our services"
							list={[
								'Design Thinking Workshops',
								'UX Design',
								'UI Design',
								'Headless web development',
								'Content support',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Background</h3>
						<p>
							ECA was in the midst of a transformation of epic
							proportions of both its internal and digital
							organization. This culminated in the move to a new
							emblematic building and the regrouping of teams from
							different business verticals. Against this backdrop,
							the ECA website needed to be overhauled to
							faithfully reflect all these transformations.
						</p>

						<h3>The challenges</h3>
						<p>
							One of the major challenges was to overhaul the
							site’s information architecture, made up of over
							1,500 pages, for audiences with very different
							needs. We aimed to achieve this by successfully
							aligning the three business verticals and six
							cross-functional teams.
						</p>

						<h3>Our solution</h3>
						<p>
							The creation of a tailor-made Design Thinking
							process to match the site's needs, aspirations and
							challenges to the realities of its users. We also
							created a new technological platform to lay a solid
							foundation for ECA's future website, based on our
							high-performance and secure headless WordPress
							technology.
						</p>
					</div>
				</div>
				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Strategic workshops UX Design</h3>
						<p>
							The Design Thinking method was implemented through a
							series of 14 participative workshops, involving over
							20 ECA employees. This approach was supported by
							both quantitative and qualitative analysis,
							providing a rigorous framework for confirming or
							disproving initial hypotheses.
						</p>
						<p>
							This data was used to define the information
							architecture and develop the wireframes, which were
							then subjected to user testing to ensure the
							relevance and effectiveness of the paths.
						</p>
					</div>
					<div
						className="column__media text-content"
						style={{ gridRow: 1 }}
					>
						<Video
							src="aa33e6f0d846498a1db9ddaf832ffa94"
							alt=""
							className="video__middle"
						/>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_1.childImageSharp}
					alt="Site web ECA"
				/>

				<Image
					className="col-full grid-spacing"
					{...data.image_2.childImageSharp}
					alt="Pages du site web ECA"
				/>

				<div className="box text-content">
					<h3>A streamlined digital identity with a human face</h3>
					<div className="list-two-columns grid-spacing">
						<div className="text-content">
							<p>
								<b>A new visual identity</b>
								<br />
								The new digital signature catapults the ECA
								brand into a new era, actively helping to
								reshape the way the company is perceived by its
								various audiences. The new, sleek and luminous
								digital identity, with its prominent white
								color, symbolizes transparency and trust. The
								structured grid is deliberately designed to
								convey an emotional sense of security, and uses
								“breathing zones” to give an impression of
								enhanced space.
							</p>
						</div>
						<div className="text-content">
							<p>
								<b>Imagery that expresses proximity</b>
								<br />
								By depictinginteractions in everyday settings,
								the imagery is intentionally designed to be warm
								and deeply human. The imagery tells the story of
								a close-knit company that fits into your
								reality.
							</p>
						</div>
					</div>
				</div>

				<Video
					className="col-full"
					src="38f51636f470b9223b38a57e7eb5f7a9"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_3.childImageSharp} alt="Assurances" />
					<Image
						{...data.image_4.childImageSharp}
						alt="Site internet ECA"
					/>
				</Gallery>

				<Blockquote
					modifiers="big"
					uptitle="Customer testimonial"
					quote="A wonderful encounter with passionate people who are true experts, offering us peace of mind as we progressed through this cross-functional project involving numerous players."
					more={
						<>
							<span className="text-content">
								Our satisfaction at the end of the project was
								down to many reasons. We particularly
								appreciated the expertise and wide range of
								skills assigned to the project: the creativity,
								the sense of work well done, the rigorous and
								pragmatic design thinking and project
								methodology proposed all contributed greatly to
								the achievement of the defined objectives.
							</span>
							<span className="text-content">
								The collaboration and mutual trust between
								superhuit and our teams offered us peace of mind
								as we progressed through this cross-functional
								project involving numerous players. A wonderful
								encounter with passionate people who are truly
								experts in their field.
							</span>
						</>
					}
					cite="Rafael Stuker - ECA Communications Manager"
				/>

				<Gallery className="col-full" modifiers="gutter">
					<Image
						{...data.image_5.childImageSharp}
						alt="Site internet ECA sur tablette"
					/>
					<Image
						{...data.image_6.childImageSharp}
						alt="Site internet ECA"
					/>
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_7.childImageSharp}
					alt="Site internet ECA mobiles"
				/>

				<div className="box text-content">
					<h3>Headless Wordpress solution</h3>
					<div className="list-two-columns grid-spacing">
						<div className="text-content">
							<p>
								The site relies on our headless technology based
								on NextJS/React, which uses Wordpress as its
								CMS.
							</p>
							<p>
								The aim is to offer the best possible conditions
								for overcoming SEO, security, performance and
								scalability challenges.
							</p>
						</div>
						<ItemLogo
							image={{
								src: nextLogo,
								alt: 'Logo NextJS',
							}}
							className="element-center element-spacing"
							imgStyle={{ width: '228px', maxWidth: 'unset' }}
						/>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default ProjectEcaPage;
